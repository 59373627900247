import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Footer } from '../components/Footer/Footer';
import { Header } from '../components/Header/Header';
import { IftarTime } from '../components/IftarTime/IftarTime';
import { ListTable } from '../components/ListTable/ListTable';
import { TabList } from '../components/TabList/TabList';

export const Home = () => {


  const[sehriTime,setSehriTime] = useState()
  const[iftarTime,setIftarTime] = useState()
  const[timesList,setTimesList] = useState([])
  const[loading,setLoading] = useState(true)

  useEffect(() => {

    const fetchData = async() => {
      axios.get('https://apibeta.iqra-live.com/api/v2/sub-info/ramadan-timing',{
      //axios.get('https://najat.com.bd/api/v1/get/iqra/ramadan/time',{
        headers: {
          //'Authorization' : 'Bearer 1pake4mh5ln64h5t26kpvm3iri '
        }
      })
      .then((response) => {
        setIftarTime(response.data.data?.today_time.ifter_time)
        setSehriTime(response.data.data.today_time.sehri_time)
        setTimesList(response.data.data?.ramadan_time)
        setLoading(false);
      })
      .catch(err => console.log(err))
    }

    fetchData()

  },[]);

  return (
    <>
      {
        loading ? <div>loading...</div> : 
          <div className='block-1080'>
            <Header />
            {sehriTime && iftarTime && <IftarTime sehriTime1={sehriTime} iftarTime1={iftarTime}/>}
            <TabList />
            <ListTable loading={loading} timesList={timesList}/>
            <Footer />
          </div>
      }
      
    </>
  )
}
